import React, { useState } from "react"
import styled from "@emotion/styled"
import Text from "../../text"
import { mq } from "../../helpers"

import siteImg from "./images/sm-internet.svg"
import instagramImg from "./images/sm-instagram.svg"
import quotesImg from "./images/quotes.svg"
import arrowBack from "./images/arrow-back.svg"
import arrowForward from "./images/arrow-forward.svg"

const quotes = [
  {
    text:
      "O Labor otimiza os processos para reduzir tempo gasto com atividades supérfluas, sobrando tempo para você se dedicar com que o mais importa.",
    author: "Mariana Andrade - Arquiteta e Urbanista - Arqflex",
    instagram: "https://www.instagram.com/arqflex_marina/",
    site: "http://www.arqflexiluminacao.com.br/",
  },
  {
    text:
      "Antes do Labor eu utilizava planilhas no Excel, mas achava que tava jogando tempo fora. Hoje com o Labor é mais simples e eficiente.",
    author: "Cristiano Almeida - Arquiteto e Urbanista - Em Planta Arquitetura",
    instagram: "https://www.instagram.com/emplantaarq/",
  },
]

const Wrapper = styled.div`
  ${mq({
    position: "relative",
    background: "#f6f7fb",
    padding: ["69px 30px 39px", null, "69px 53px 39px", "74px 50px 64px"],
    backgroundImage: [`url(${quotesImg})`],
    backgroundRepeat: "no-repeat",
    backgroundPosition: ["18px 54px", null, "41px 54px", "20px 60px"],
  })}
`

const QuoteArrow = styled.button`
  ${({ forward, disabled }) =>
    mq({
      width: "20px",
      height: "20px",
      maskImage: `url(${forward ? arrowForward : arrowBack})`,
      backgroundColor: "#4680fe",
      objectFit: "contain",
      padding: 0,
      margin: 0,
      border: 0,
      opacity: `${disabled ? 0.25 : 1}`,
      marginRight: "14px",
      ":last-child": {
        marginRight: 0,
      },
    })}
`

const FloatingBox = styled.div`
  ${mq({
    position: "absolute",
    top: "-28px",
    left: "41px",
    width: "186px",
    backgroundColor: "#4680fe",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px",
    color: "#ffffff",
    padding: "10px 13px",
  })}
`

const QuoteText = styled(Text)`
  ${mq({
    fontSize: ["20px", null, null, "26px"],
    fontWeight: "bold",
    color: "#5f5f5f",
  })}
`

const AuthorText = styled(Text)`
  ${mq({
    marginTop: "35px",
    fontSize: ["13px", null, null, "15px"],
  })}
`

export default function Quotes() {
  const [index, setIndex] = useState(0)
  const { text, author, site, instagram } = quotes[index]
  const backDisabled = index === 0
  const forwardDisabled = index === quotes.length - 1

  return (
    <Wrapper>
      <FloatingBox>
        Use seu tempo com o que <strong>realmente importa</strong>
      </FloatingBox>
      <div>
        <QuoteText>{text}</QuoteText>
        <AuthorText>{author}</AuthorText>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "21px",
          }}
        >
          <div>
            {instagram ? (
              <a
                href={instagram}
                rel="noopener noreferrer"
                target="_blank"
                style={{ marginRight: "26px" }}
              >
                <img alt="Instagram" src={instagramImg} />
              </a>
            ) : null}
            {site ? (
              <a href={site} rel="noopener noreferrer" target="_blank">
                <img alt="Link para site externo" src={siteImg} />
              </a>
            ) : null}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <QuoteArrow
              aria-label="anterior"
              back
              disabled={backDisabled}
              onClick={() => (backDisabled ? null : setIndex(index - 1))}
            />
            <Text
              style={{
                marginRight: "14px",
                color: "#4680fe",
                fontSize: "11px",
              }}
            >
              {(index + 1).toString().padStart(2, "0")}
            </Text>
            <QuoteArrow
              aria-label="proximo"
              forward
              disabled={forwardDisabled}
              onClick={() => (forwardDisabled ? null : setIndex(index + 1))}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
