import React from "react"
import HomePage from "../components/pages/home"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <>
      <SEO title="Controle de Horas" />
      <HomePage />
    </>
  )
}

export default IndexPage
